// import { loader } from 'graphql.macro';
import request from '../../utils/request';
import {
	CLOSE_FILTRES,
	FiltresActionTypes,
	FiltresThunkResult,
	GetFiltresResponse,
	GET_FILTRES_FAIL,
	GET_FILTRES_START,
	GET_FILTRES_SUCCESS,
	OPEN_FILTRE,
} from './types';
import { query } from './query/getFiltres';

// const query = loader('./getFiltres.graphql');

export function getFiltres(): FiltresThunkResult {
	return async function (dispatch) {
		try {
			dispatch({ type: GET_FILTRES_START });
			const { labels } = await request<GetFiltresResponse>(query);
			const filtres = [
				{ nom: 'Labels', slug: 'label', isOpen: false, options: labels },
			];
			dispatch({ type: GET_FILTRES_SUCCESS, filtres });
		} catch (err) {
			dispatch({ type: GET_FILTRES_FAIL, error: err.message });
		}
	};
}

export function openFiltre(filtreSlug: string): FiltresActionTypes {
	return { type: OPEN_FILTRE, filtreSlug };
}

export function closeFiltres(): FiltresActionTypes {
	return { type: CLOSE_FILTRES };
}
