import gql from "graphql-tag"

export const query = gql`
query getCommandes(
    $where: CommandeWhereInput
	$orderBy: CommandeOrderByInput
	$first: Int
	$skip: Int
) {
    countCommandes(where: $where)
    commandes(where: $where, skip: $skip, take: $first, orderBy: $orderBy) {
        id
        nom
        com
        statut
        numero
        favorite
        heureCommande
        dateLivraison
		recurrenceMois {
            date
        }
		recurrenceSemaine {
            jour
        }
		lignes {
			produit {
                nom
            }
        }
        total
    }
}
`