import gql from 'graphql-tag';

export const query = gql`
    {
	labels {
		nom
		slug
		abreviation
	}
}

`