import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { useTitle } from '../../../hooks/useTitle';
import { AppState } from '../../../store';
import { hideLoginError, login } from '../../../store/compte/actions';
import { UserType } from '../../../store/compte/types';
import { passwordMinLength } from '../../../utils/password';
import Button from '../../Button/Button';
import ErrorMessage from '../../Form/ErrorMessage/ErrorMessage';
import Form from '../../Form/Form';
import Input from '../../Form/Input/Input';
import SmallLink from '../../Form/SmallLink/SmallLink';
import styles from './Connexion.module.scss';
// import gql from 'graphql-tag';
// import { useMutation } from '@apollo/client';

export interface Props extends RouteComponentProps {
	type: UserType;
	isLoggingIn: boolean;
	isLoggedIn: boolean;
	loginError?: string;
	login: typeof login;
	hideLoginError: typeof hideLoginError;
}

export function _Connexion({
	location,
	isLoggingIn,
	isLoggedIn,
	loginError,
	type,
	login,
	hideLoginError,
}: Props) {
	useTitle('Connexion' + (type === 'Client' ? '' : ' ' + type.toLowerCase()));

	const searchParams = new URLSearchParams(location.search);

	const [code, setCode] = useState(searchParams.get('code') || '');
	const [password, setPassword] = useState('');

	let redirect = '/';
	if (type === 'Commercial') redirect = '/liste-clients-commercial';
	else if (type === 'Admin') redirect = '/admin';

	function changeCode(val: string) {
		if (loginError) hideLoginError();
		setCode(val.toUpperCase());
	}

	function changePassword(val: string) {
		if (loginError) hideLoginError();
		setPassword(val);
	}

	// const test = gql`
	// 	mutation ($code: String!, $password: String!, $type: USER_TYPE!) {
	// 		user: login(code: $code, password: $password, type: $type) {
	// 			type: __typename
	// 			... on Client {
	// 				nom
	// 				prenom
	// 				code
	// 			}
	// 		}
	// 	}
	// `;

	// const [login, { loading, error, data }] = useMutation(test);
	// const handleLogin = async () => {
	// 	try {
	// 		const result = await login({
	// 			variables: {
	// 				code,
	// 				password,
	// 				type,
	// 			},
	// 		});
	// 		isLoggedIn = true;
	// 	} catch (err) {
	// 		console.log(err);
	// 	}
	// };

	return isLoggedIn ? (
		<Redirect to={redirect} />
	) : (
		<Form onSubmit={() => login({ code, password, type })}>
			<h1 className={styles.title}>Accès {type.toLowerCase()}</h1>

			<Input
				label="Code"
				value={code}
				setValue={changeCode}
				required
				autoFocus
			/>

			<Input
				label="Mot de passe"
				type="password"
				value={password}
				setValue={changePassword}
				minLength={passwordMinLength}
				required
			/>

			{type === 'Client' && (
				<SmallLink to={'/mot-de-passe-oublie' + (code ? '?code=' + code : '')}>
					J'ai oublié mon mot de passe...
				</SmallLink>
			)}

			{type === 'Commercial' && (
				<SmallLink to={'/connexion' + (code ? '?code=' + code : '')}>
					Accès client
				</SmallLink>
			)}

			{type === 'Client' && (
				<SmallLink to={'/connexion-commercial' + (code ? '?code=' + code : '')}>
					Accès commercial
				</SmallLink>
			)}

			<ErrorMessage>{loginError}</ErrorMessage>

			<Button submit loading={isLoggingIn}>
				Se connecter
			</Button>
		</Form>
	);
}

function mapStateToProps(state: AppState) {
	return {
		isLoggingIn: state.compte.isLoggingIn,
		isLoggedIn: state.compte.isLoggedIn,
		loginError: state.compte.error,
	};
}

export default withRouter(
	connect(mapStateToProps, { login, hideLoginError })(_Connexion),
);
