import gql from 'graphql-tag';

export const getLoggedInUserQuery = gql`
	query getLoggedInUser {
		user: getLoggedInUser {
			type: __typename
			... on Client {
				nom
				prenom
				code
			}
			... on Commercial {
				nom
				prenom
				code
				clients {
					code
					nom
					prenom
					groupe {
						nom
					}
				}
			}
			... on Admin {
				nom
				prenom
			}
		}
	}
`;
