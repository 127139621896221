import gql from 'graphql-tag';

export const getAlertesQuery = gql`{
    list: alertes {
        id
        createdAt
        texte
        url
        lu
    }
}
`