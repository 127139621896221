import gql from 'graphql-tag';

export const getInfoProduitsPanierQuery = gql`
	query getInfoProduitsPanier($codes: [String!]!, $date: Date) {
		produits(where: { code : { in : $codes }}) {
			nom
			code
			ucParDefaut
			facteurU
			imageSrc
			disabled
			isForbiddenForMe(date: $date)
			categorie {
				slug
			}
			labels {
				abreviation
			}
			tarif {
				uf
				prix
			}
		}
	}
`