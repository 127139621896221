import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppState } from '../../../store';
import { resetPanier } from '../../../store/panier/actions';
import { JourDeLaSemaine } from '../../../types';
import request from '../../../utils/request';
import Button from '../../Button/Button';
import Loader from '../../Loader/Loader';
import CenteredPopin from '../../Popin/CenteredPopin/CenteredPopin';
import styles from './PopinCreationCommande.module.scss';
import dateFormat from 'dateformat';

const mutation = loader( './createCommande.graphql' );

export interface Props {
	isOpen: boolean;
	nom?: string;
	com?: string;
	favorite: boolean;
	heureCommande?: string;
	dateLivraison?: Date;
	recurrenceMois?: number;
	recurrenceSemaine?: JourDeLaSemaine[];
	lignes: { codeProduit: string; prixUnite: number; quantite: number; }[];
	resetPanier: typeof resetPanier;
	close: () => void;
}

export function _PopinCreationCommande ( {
	isOpen,
	close,
	resetPanier,
	...panier
}: Props ) {
	const [ loading, setLoading ] = useState( false );
	const [ commandeCree, setCommandeCree ] = useState( false );
	const [ error, setError ] = useState( '' );

	// On reset la popin uniquement quand on ouvre la popin
	useEffect( () => {
		if ( !isOpen ) return;
		setLoading( false );
		setCommandeCree( false );
		setError( '' );
	}, [ isOpen ] );

	// Fonction qui passe la commande
	async function placeOrder () {
		try {
			panier.heureCommande =
				new Date().getHours() +
				':' +
				( new Date().getMinutes() < 10 ? '0' : '' ) +
				new Date().getMinutes();
			const formatedDate = dateFormat( panier.dateLivraison, 'yyyy-mm-dd' );
			panier.dateLivraison = formatedDate as any; // Not a good practice but i only have this solution for the moment
			setError( '' );
			setLoading( true );
			await request( mutation, panier );
			setCommandeCree( true );
			resetPanier();
		} catch ( err ) {
			setError( err.message );
		} finally {
			setLoading( false );
		}
	}

	return (
		<CenteredPopin
			isOpen={ isOpen }
			onCacheClick={ () => {
				// On ne permet pas de fermer la popin si c'est loading
				if ( !loading ) close();
			} }
		>
			<div className={ styles.popin }>
				{ loading ? (
					<Loader />
				) : error ? (
					<p className={ styles.error }>{ error }</p>
				) : commandeCree ? (
					<>
						<h1>
							Commande passée <span className={ styles.doneCheck } />
						</h1>

						<p>
							Votre commande à bien été passée. Vous pouvez la retrouver dans
							vos commandes et suivre son statut.
						</p>

						<Link to="/commandes" onClick={ close }>
							<Button>Mes commandes</Button>
						</Link>
					</>
				) : (
					<>
						<h1>Attention</h1>

						<p>
							Les prix affichés sont des estimations et n'engagent pas le
							fournisseur. En cliquant sur le bouton ci-dessous, vous vous
							engagez à payer le montant figurant sur la facture que vous
							recevrez.
						</p>

						<Button onClick={ placeOrder }>Je confirme ma commande</Button>
					</>
				) }
			</div>
		</CenteredPopin>
	);
}

function mapStateToProps ( state: AppState ) {
	return {
		nom: state.panier.nom,
		com: state.panier.com,
		favorite: state.panier.favorite,
		heureCommande: state.panier.heureCommande,
		dateLivraison: state.panier.dateLivraison,
		recurrenceMois: state.panier.recurrenceMois,
		recurrenceSemaine: state.panier.recurrenceSemaine,
		lignes: state.panier.lignes.map( ( l ) => ( {
			uc: l.uc,
			codeProduit: l.code,
			quantite: l.quantite,
			prixUnite: l.prixUnite,
			commentaire: l.commentaire,
		} ) ),
	};
}

export default connect( mapStateToProps, { resetPanier } )(
	_PopinCreationCommande,
);
