import gql from 'graphql-tag';

export const actualites = gql`{
    list: actualites {
        id
        titre
        contenu
        imageSrc
        url
    }
}
`
