import 'whatwg-fetch';
import { client } from '../index';

type Variables = { [ key: string ]: any; };

// interface GraphQLError {
// 	message: string;
// 	locations: { line: number; column: number; }[];
// 	path: string[];
// }

// interface GraphQLResponse {
// 	data?: any;
// 	errors?: GraphQLError[];
// 	extensions?: any;
// 	status: number;
// 	[ key: string ]: any;
// }

// class ClientError extends Error {
// 	response: GraphQLResponse;

// 	constructor( response: GraphQLResponse ) {
// 		super( ClientError.extractMessage( response ) );
// 		this.name = 'ClientError';
// 		this.response = response;
// 		if ( Error.captureStackTrace ) Error.captureStackTrace( this, ClientError );
// 	}

// 	private static extractMessage ( response: GraphQLResponse ): string {
// 		try {
// 			return response.errors![ 0 ].message;
// 		} catch ( e ) {
// 			return `Erreur ${ response.status }`;
// 		}
// 	}
// }

async function request<TResponse> (
	query: any,
	variables?: Variables,
	file?: File,
): Promise<TResponse> {

	// const body = new FormData();
	// body.append( 'operationName', JSON.stringify( { query, variables } ) );
	// const formData = new FormData();

	if ( file ) {
		variables = { file, ...variables };
	}

	// const response = await fetch('/api', {
	// 	method: 'POST', body: JSON.stringify({
	// 		query,
	// 		variables
	// 	})

	// })

	const { data } = await client.mutate( {
		mutation: query,
		variables
		// context: {
		// 	fetchOptions: {
		// 		method: 'POST',
		// 		body,
		// 		headers: {
		// 			'Content-Type': 'multipart/form-data'
		// 		}
		// 	}
		// }
	} );

	// const { data } = await test;

	// const contentType = response.headers.get('Content-Type');
	// const isJson = contentType && contentType.startsWith('application/json');
	// const result = isJson ? await response.json() : await response.text();

	if ( data ) {
		return data;
	} else {
		throw new Error( 'error' );
	}
}

export default request;
