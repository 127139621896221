// import { loader } from 'graphql.macro';
import request from '../../utils/request';
import {
	Categorie,
	CategoriesThunkResult,
	GET_CATEGORIES_FAIL,
	GET_CATEGORIES_START,
	GET_CATEGORIES_SUCCESS,
} from './types';
import { query } from './query/getCategories'

// const query = loader('./getCategories.graphql');

export function getCategories(): CategoriesThunkResult {
	return async function (dispatch) {
		try {
			dispatch({ type: GET_CATEGORIES_START });
			type TResponse = { list: Categorie[] };
			const { list } = await request<TResponse>(query);
			dispatch({ type: GET_CATEGORIES_SUCCESS, list });
		} catch (err) {
			dispatch({ type: GET_CATEGORIES_FAIL, error: err.message });
		}
	};
}
