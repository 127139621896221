import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'destyle.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';
import './styles/outdated.scss';
import './styles/style.scss';
import {
	ApolloClient,
	InMemoryCache,
	ApolloProvider,
	ApolloLink,
	split
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

const uri = `${window.location.host}/api`;
const protocol = window.location.protocol;
const httpUri = `${protocol}//${uri}`;
const wsUri = `ws://${uri}`;

const wsLink = new GraphQLWsLink(
	createClient({
		url: wsUri
	})
);

const uploadLink = createUploadLink({
	uri: httpUri
});

const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		);
	},
	wsLink,
	uploadLink as unknown as ApolloLink
);

export const client = new ApolloClient({
	uri: httpUri,
	link: splitLink,
	cache: new InMemoryCache()
});

const store = configureStore();

const root = document.getElementById('root');

const render = (Component: any) => {
	return ReactDOM.render(
		<ApolloProvider client={client}>
			<Provider store={store}>
				<BrowserRouter>
					<Component />
				</BrowserRouter>
			</Provider>
		</ApolloProvider>,
		document.getElementById('root')
	);
};

if (root) {
	render(App); // Mandatory for test
}

// Hot Module Reloading
if ((module as any).hot) {
	(module as any).hot.accept('./components/App/App', () => render(App));
}

// Service Worker
serviceWorker.register({
	// onSuccess: () => {
	// 	console.log(`Le serviceWorker a bien été installé`);
	// },
	onUpdate: () => {
		console.info(
			`Une mise à jour est disponible pour cette application. Veuillez fermer et rouvrir cet onglet pour avoir les dernières modifications.`
		);
	}
});
