import gql from 'graphql-tag';

export const loginQuery = gql`
	mutation ($code: String!, $password: String!, $type: USER_TYPE!) {
		user: login(code: $code, password: $password, type: $type) {
			type: __typename
			... on Client {
				nom
				prenom
				code
			}
			... on Commercial {
				nom
				prenom
				code
				clients {
					code
					nom
					prenom
					groupe {
						nom
					}
				}
			}
			... on Admin {
				nom
				prenom
				password
			}
		}
	}
`;
