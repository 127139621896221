import gql from 'graphql-tag';


export const getProducts = gql`
query getProducts($skip: Int, $first: Int, $where: ProduitWhereInput) {
    countTotal: countProduits(where: $where)

    produits(skip: $skip, first: $first, where: $where) {
        nom
        code
        imageSrc
        ucParDefaut
        facteurU
		categorie {
            slug
        }
		labels {
            slug
            abreviation
        }
		tarif {
            uf
            prix
        }
        isFavoriteForMe
    }
}
`