import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { useTitle } from '../../hooks/useTitle';
import { AppState } from '../../store';
import { downloadManyFiles } from '../../utils/download';
import request from '../../utils/request';
import { isIOS } from '../../utils/userAgent';
import ExportSelectedButton from '../ExportSelectedButton/ExportSelectedButton';
import FilterByDateRange from '../FilterByDateRange/FilterByDateRange';
import OrderBy from '../OrderBy/OrderBy';
import TableHeader from '../Table/TableHeader/TableHeader';
import TablePagination from '../Table/TablePagination/TablePagination';
import TableWrapper from '../Table/TableWrapper/TableWrapper';
import styles from './Avoirs.module.scss';
import ListeAvoirs from './ListeAvoirs/ListeAvoirs';

const getAvoirsQuery = loader('./getAvoirs.graphql');
const getAvoirsByIdQuery = loader('./getAvoirsById.graphql');
const itemsPerPage = 10;

export interface Avoir {
	id: string;
	filePath: string;
	numeroAvoir: string;
	dateAvoir: Date;
}

export interface Props extends RouteComponentProps {
	codeClient?: string;
}

export function _Avoirs({ codeClient, location }: Props) {
	useTitle('Mes avoirs');

	const [avoirs, setAvoirs] = useState<Avoir[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	const [selectedIDs, setSelectedIDs] = useState<string[]>([]);
	const [downloadLoading, setDownloadLoading] = useState(false);

	const searchParams = new URLSearchParams(location.search);
	const pageIndex = +(searchParams.get('page') || 1);
	const orderBy = searchParams.get('orderBy') || '';
	const order = searchParams.get('order') || '';
	const dateStart = searchParams.get('dateStart');
	const dateEnd = searchParams.get('dateEnd');

	// Fetch des avoirs
	useEffect(() => {
		if (!orderBy || !order) return;

		let isMounted = true;
		setLoading(true);
		const orderByObject: { [key: string]: string; } = {};
		orderByObject[orderBy] = order.toLowerCase()
		const params = {
			skip: itemsPerPage * (pageIndex - 1),
			first: itemsPerPage,
			orderBy: orderByObject,
			where: {
				client: { code: codeClient },
				...(dateStart && { dateAvoir: { gte: dateStart } }),
				// ...(dateEnd && { dateAvoir : { lte : dateEnd + 'T23:59:59.999Z'} }),
				...(dateEnd && { dateAvoir: { lte: dateEnd } }),
				...(dateStart && dateEnd && { dateAvoir: { gte: dateStart, lte: dateEnd } }),
			},
		};

		type TResponse = { countAvoirs: number; avoirs: Avoir[] };
		request<TResponse>(getAvoirsQuery, params)
			.then(({ avoirs, countAvoirs }) => {
				if (!isMounted) return;
				setAvoirs(avoirs);
				setTotalCount(countAvoirs);
				setError('');
			})
			.catch((err) => setError(err.message))
			.finally(() => setLoading(false));

		return () => {
			isMounted = false;
		};
	}, [location.key, order, orderBy, codeClient, pageIndex, dateStart, dateEnd]);

	// Download des avoirs sélectionnées
	function downloadSelected() {
		setDownloadLoading(true);

		type TResponse = {
			avoirs: Pick<Avoir, 'filePath' | 'numeroAvoir'>[];
		};
		request<TResponse>(getAvoirsByIdQuery, { codeClient, selectedIDs })
			.then(({ avoirs }) => {
				setSelectedIDs([]);
				downloadManyFiles(
					avoirs.map((f: any) => ({ href: f.filePath, name: f.numeroAvoir })),
				);
			})
			.catch((err) => alert(err.message))
			.finally(() => setDownloadLoading(false));
	}

	if (!orderBy || !order) {
		return <Redirect to="/avoirs?orderBy=dateAvoir&order=DESC" />;
	}

	return (
		<TableWrapper>
			<TableHeader>
				<div className={styles.orderAndFilter}>
					<OrderBy
						fields={[
							{ name: "Date d'avoir", slug: 'dateAvoir' },
							{ name: "Numéro d'avoir", slug: 'numeroAvoir' },
						]}
					/>
					<FilterByDateRange label="Date" />
				</div>

				{!isIOS && (
					<ExportSelectedButton
						loading={downloadLoading}
						number={selectedIDs.length}
						onClick={downloadSelected}
					/>
				)}
			</TableHeader>

			<ListeAvoirs
				error={error}
				loading={loading}
				avoirs={avoirs}
				selectableRows={{ selectedIDs, setSelectedIDs }}
			/>

			<TablePagination
				error={!!error}
				loading={loading}
				totalItemsCount={totalCount}
				itemsCountPerPage={itemsPerPage}
			/>
		</TableWrapper>
	);
}

function mapStateToProps(state: AppState) {
	return { codeClient: state.compte.code };
}

export default withRouter(connect(mapStateToProps)(_Avoirs));
