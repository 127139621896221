// import { loader } from 'graphql.macro';
import formatDate from 'dateformat';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import slugify from 'slugify';
import { useTitle } from '../../hooks/useTitle';
import { AppState } from '../../store';
import { Produit } from '../../types/produit';
import request from '../../utils/request';
import Pagination from '../Pagination/Pagination';
import styles from './ResultatsRecherche.module.scss';
import VignetteProduit from './VignetteProduit/VignetteProduit';
import { getProducts } from './query/getProducts';

// const query = loader('./getProducts.graphql');
const itemsPerPage = 15;

export interface Props extends RouteComponentProps {
	codeClient?: string;
	dateLivraison?: Date;
}

export function _ResultatsRecherche ( {
	location,
	codeClient,
	dateLivraison,
}: Props ) {
	useTitle( 'Recherche' );

	const [ products, setProducts ] = useState<Produit[]>( [] );
	const [ countTotal, setCountTotal ] = useState( 0 );
	const [ loading, setLoading ] = useState( false );
	const [ error, setError ] = useState( '' );

	// Set up date to get product by date
	const date = formatDate( new Date(), 'isoUtcDateTime' );

	// GET params
	const searchParams = new URLSearchParams( location.search );
	const pageIndex = +( searchParams.get( 'page' ) || 1 );
	const activeSelection = searchParams.get( 'selection' );
	const activeCategorie = searchParams.get( 'categorie' );
	const activeLabel = searchParams.get( 'label' );
	let activeQuery = searchParams.get( 'query' );
	if ( activeQuery ) activeQuery = slugify( activeQuery, { lower: true } );

	// À chaque changement de route
	useEffect( () => {
		let isMounted = true;
		setLoading( true );

		const params = {
			skip: itemsPerPage * pageIndex - itemsPerPage,
			first: itemsPerPage,
			where: {
				disabled: false,
				interdictions: {
					none: {
						AND: [
							{
								OR: [
									{ produit: { autorisations: { none: { client: { code: { equals: codeClient } } } } } },
									{
										produit: {
											autorisations: {
												none: {
													dateDebut: { lte: date },
													dateFin: { gte: date },
												},
											}
										},
									},
								],
							},
							{
								OR: [
									{
										produit: {
											autorisations: { none: { dateDebut: { equals: null }, dateFin: { equals: null } } },
										},
									},
								],
							},
							{
								OR: [
									{
										dateDebut: { lte: date },
										dateFin: { gte: date },
									},
									{ dateDebut: null, dateFin: null },
									{
										dateDebut: { lt: dateLivraison },
										dateFin: { gt: dateLivraison },
									},
								],
							},
							{
								OR: [
									{
										NOT: [
											{
												exceptgroupe: {
													clients: { some: { code: { equals: codeClient } } },
												},
											},
										],
									},
								],
							},
							{
								OR: [
									{
										groupe: {
											clients: { some: { code: { equals: codeClient } } },
										},
									},
									{
										OR: [
											{
												exceptgroupe: {
													clients: { some: { code: { not: codeClient } } },
												},
											},
											{ exceptclient: { code: { not: codeClient } } },
										],
									},
									{
										OR: [ { client: { code: { equals: codeClient } } } ],
									},
								],

							},
						],
					},
				},
				...( activeQuery && {
					AND: activeQuery.split( '-' ).map( ( q ) => ( {
						OR: [
							// { code_not_contains: q.toUpperCase() },
							{ code: { contains: q.toUpperCase() } },
							{ slug: { contains: q.replace( /s$/, '' ) } },
							{ selections: { some: { slug: { contains: q } } } },
							{ categorie: { slug: { contains: q } } },
							// { selections: { some: { slug: { contains: q } } } },
							{ labels: { some: { keywords: { contains: q } } } },
						],
					} ) ),
				} ),
				...( activeSelection && {
					selections: { some: { slug: { equals: activeSelection } } },
				} ),
				...( activeCategorie && {
					categorie: { slug: { equals: activeCategorie } },
				} ),
				...( activeLabel && {
					labels: { some: { slug: { equals: activeLabel } } },
				} ),
			},
		};

		type TResponse = { countTotal: number; produits: Produit[]; };
		request<TResponse>( getProducts, params )
			.then( ( { countTotal, produits } ) => {
				if ( !isMounted ) return;
				setProducts( produits );
				setCountTotal( countTotal );
				setError( '' );
			} )
			.catch( ( err ) => setError( err.message ) )
			.finally( () => setLoading( false ) );

		return () => {
			isMounted = false;
		};
	}, [
		pageIndex,
		activeCategorie,
		activeLabel,
		activeQuery,
		activeSelection,
		codeClient,
		dateLivraison,
		date,
	] );

	return error ? (
		<p className={ styles.warning }>{ error }</p>
	) : (
		<>
			<Pagination
				showCount
				loading={ loading }
				className={ styles.pagination }
				totalItemsCount={ countTotal }
				itemsCountPerPage={ itemsPerPage }
			/>

			{/* Liste des produits */ }
			<ul className={ styles.list }>
				{ loading
					? [ ...new Array( itemsPerPage ) ].map( ( _, i ) => (
						<li key={ i } className={ styles.item }>
							<div className={ styles.skeleton } />
						</li>
					) )
					: products.map( ( produit ) => (
						<li key={ produit.code } className={ styles.item }>
							<VignetteProduit { ...produit } />
						</li>
					) ) }

				{/* Fakes (for flex display) */ }
				{ [ ...new Array( 7 ) ].map( ( _, i ) => (
					<li key={ i } className={ [ styles.item, styles.fake ].join( ' ' ) } />
				) ) }
			</ul>

			<Pagination
				loading={ loading }
				className={ styles.pagination }
				totalItemsCount={ countTotal }
				itemsCountPerPage={ itemsPerPage }
			/>
		</>
	);
}

function mapStateToProps ( state: AppState ) {
	return {
		codeClient: state.compte.code,
		dateLivraison: state.panier.dateLivraison,
	};
}

export default withRouter( connect( mapStateToProps )( _ResultatsRecherche ) );
