import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import MediaQuery from 'react-responsive';
import { JourDeLaSemaine, StatutCommande } from '../../../types';
import { media } from '../../../utils/breakpoints/breakpoints';
import { convertStatut, thinSpace } from '../../../utils/french';
import request from '../../../utils/request';
import BtnBack from '../../BtnBack/BtnBack';
import BtnFavori from '../../BtnFavori/BtnFavori';
import DatePicker from '../../DatePicker/DatePicker';
import NomCommande from '../../NomCommande/NomCommande';
import Recurrence, {
	RecurrenceMois,
	RecurrenceSemaine,
} from '../../Recurrence/Recurrence';
import { Commande } from '../DetailCommande';
import styles from './Header.module.scss';

const mutationUpdateCommande = loader('./updateCommande.graphql');

export interface Props {
	id?: string;
	nom?: string;
	from: string;
	favorite?: boolean;
	initLoading: boolean;
	dateLivraison?: Date;
	statut?: StatutCommande;
	recurrenceMois?: { date: number };
	recurrenceSemaine?: { jour: JourDeLaSemaine }[];
	updateCommandeState: (params: {
		favorite?: Commande['favorite'];
		recurrenceMois?: Commande['recurrenceMois'];
		recurrenceSemaine?: Commande['recurrenceSemaine'];
	}) => void;
}

function Header({
	id,
	nom,
	from,
	statut,
	favorite,
	initLoading,
	dateLivraison,
	recurrenceMois,
	recurrenceSemaine,
	updateCommandeState,
}: Props) {
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	async function updateCommandeRecurrence(
		mois?: RecurrenceMois,
		semaine?: RecurrenceSemaine,
	) {
		try {
			updateCommandeState({
				recurrenceMois: mois ? { date: mois } : undefined,
				recurrenceSemaine: semaine
					? semaine.map((s) => ({ jour: s }))
					: undefined,
			});
			await request(mutationUpdateCommande, {
				id,
				...{
					recurrenceMois: {
						...(recurrenceMois && { delete : true }),
						...(mois && {
							upsert: { update: { date : mois }, create: { date : mois } },
						}),
					},
					recurrenceSemaine: {
						// deleteMany: { jour_not_in: semaine },
						deleteMany: { jour : { not : { in : semaine }} },
						...(semaine && { create: semaine.map((s) => ({ jour: s })) }),
					},
				},
			});
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	}

	async function updateCommande(params: { nom?: string; favorite?: boolean }) {
		setLoading(true);
		try {
			await request(mutationUpdateCommande, { id, ...params });
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	}
	return (
		<>
			{/* Erreur à l'update */}
			{error && (
				<p className={styles.error}>
					Erreur lors de la mise à jour de la commande{thinSpace}: {error}
				</p>
			)}

			{/* 1ère ligne */}
			<div className={styles.line}>
				<MediaQuery query={media['>=tablet']}>
					<BtnBack to={from} withText />
				</MediaQuery>

				{initLoading ? (
					<>
						<div className={[styles.skeleton, styles.nom].join(' ')} />
						<div className={styles.skeleton} />
					</>
				) : (
					<>
						<NomCommande
							nom={nom}
							loading={loading}
							className={styles.nom}
							changeNom={(nom: string) => updateCommande({ nom })}
						/>

						<p className={styles.statutCommande}>
							<strong>État{thinSpace}:</strong> {convertStatut(statut!)}
						</p>

						<BtnFavori
							checked={favorite!}
							onClick={() => {
								updateCommande({ favorite: !favorite });
								updateCommandeState({ favorite: !favorite });
							}}
							title={
								favorite
									? 'Enlever la commande des favoris'
									: 'Ajouter la commande aux favoris'
							}
						/>
					</>
				)}
			</div>

			{/* 2ème ligne */}
			<div className={styles.line}>
				{initLoading ? (
					<>
						<div className={[styles.skeleton, styles.livraison].join(' ')} />
						<div className={styles.skeleton} />
					</>
				) : (
					<>
						<DatePicker
							readOnly
							selected={dateLivraison}
							className={styles.livraison}
							label={<strong>Date de livraison{thinSpace}:</strong>}
						/>

						<Recurrence
							className={styles.recurrence}
							recurrenceMois={recurrenceMois && recurrenceMois.date}
							recurrenceSemaine={
								recurrenceSemaine && recurrenceSemaine.map((r) => r.jour)
							}
							setRecurrence={({ mois, semaine }) =>
								updateCommandeRecurrence(mois, semaine)
							}
						/>
					</>
				)}
			</div>
		</>
	);
}

export default Header;
