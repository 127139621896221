import gql from 'graphql-tag';

export const query = gql`
    {
	    list: selections {
		nom
		slug
		pictoSrc
	}
}

`